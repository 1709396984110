import CcxtPrivate from "@/Ccxt/CcxtPrivate";
import axios from "axios";
import { Dictionary } from "ccxt";

export interface CgCurrency {
  id: string;
  name: string;
  symbol: string;
  large: string;
  info?: CgCurrencyInfo;
}

export interface CgCurrencyInfo {
  platforms: CgPlatform[];
  links: { homepage: string[] };
  description: string;
}

export interface CgPlatform {
  contractAddress: string;
  assetPlatform: CgAssetPlatform;
}

export interface CgAssetPlatform {
  id: string;
  chain_identifier: number;
  name: string;
  shortname: string;
}

export class CoinGecko {
  static proxy = "";
  static headers: Record<string, string> = {};

  private static map: Dictionary<string> = {
    cryptocom: "crypto_com",
    currencycom: "currency",
    coinbasepro: "gdax",
    bitcoincom: "bitcoin_com",
    hitbtc3: "hitbtc",
    bitfinex2: "bitfinex",
    gateio: "gate",
    okx: "okex",
  };
  private static assetPlatformsById: Record<string, CgAssetPlatform> = {};
  private static currenciesById: Record<string, CgCurrency> = {};
  private static currenciesByExchange = new Map<string, Map<string, CgCurrency>>();
  private static currenciesPromiseByExchange = new Map<string, Promise<void>>();

  static async loadData(exchange: string, ccxtExchange: CcxtPrivate) {
    const exchangeSlug = this.getExchangeSlug(exchange);

    let promise = this.currenciesPromiseByExchange.get(exchangeSlug);
    if (promise === undefined) {
      const currencies = new Map<string, CgCurrency>();
      promise = this.getCurrenciesByExchange(currencies, exchangeSlug, ccxtExchange);
      this.currenciesPromiseByExchange.set(exchangeSlug, promise);
      await promise;
      this.currenciesPromiseByExchange.delete(exchangeSlug);
      this.currenciesByExchange.set(exchangeSlug, currencies);
    }
    return promise;
  }

  static getCurrency(exchange: string, currencyCode: string) {
    const exchangeSlug = this.getExchangeSlug(exchange);
    const currencies = this.currenciesByExchange.get(exchangeSlug);
    return currencies?.get(currencyCode);
  }

  private static async getCurrenciesByExchange(
    currencies: Map<string, CgCurrency>,
    exchangeSlug: string,
    ccxtExchange: CcxtPrivate,
    page = 1
  ) {
    const limit = 100;
    let url = "https://api.coingecko.com/api/v3/exchanges/" + exchangeSlug + "/tickers";
    url += `?page=${page}`;

    const proxyParams = [
      "key=" + encodeURIComponent("scp-live-e844d36188bf4aa1bc2cb948adb04ecb"),
      "url=" + encodeURIComponent(url),
      "proxified_response=true",
      //"render_js=true",
      //"proxy_pool=" + encodeURIComponent("public_residential_pool"),
      //"asp=true",
    ];
    const proxyUrl = "https://api.scrapfly.io/scrape?" + proxyParams.join("&");

    return await axios
      .get(this.proxy + proxyUrl, { headers: this.getHeaders(86400) })
      .then((res) => {
        if (res.status !== 200) {
          console.error("CMC error", res.data);
          return;
        }
        const response = res.data;
        const tickers: any[] = response.tickers;

        for (const ticker of tickers) {
          const symbol = ticker.base;
          if (currencies.has(symbol)) {
            continue;
          }
          const currency = this.currenciesById[ticker.coin_id];
          currencies.set(symbol, currency);
        }

        if (tickers.length === limit) {
          return new Promise<void>((resolve, reject) => {
            setTimeout(async () => {
              try {
                await this.getCurrenciesByExchange(currencies, exchangeSlug, ccxtExchange, page + 1);
                resolve();
              } catch (error) {
                reject(error);
              }
            }, 1);
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  static async getCurrencies() {
    //NOTE: This is the source of the search bar
    const url = this.proxy + "https://api.coingecko.com/api/v3/search";

    return await fetch(url, { headers: this.getHeaders(86400) }).then(async (res) => {
      const json: { coins: CgCurrency[] } = await res.json();
      if (!res.ok) {
        console.error("CG error", json);
        return {} as Record<string, CgCurrency[]>;
      }
      const currencies: Record<string, CgCurrency[]> = {};
      this.currenciesById = {};
      for (const currency of json.coins) {
        if (currencies[currency.symbol] === undefined) {
          currencies[currency.symbol] = [];
        }
        currencies[currency.symbol].push(currency);
        this.currenciesById[currency.id] = currency;
      }
      return currencies;
    });
  }

  static async getCurrencyInfo(currency: CgCurrency) {
    const url = "https://api.coingecko.com/api/v3/coins/" + currency.id;
    const result = await fetch(this.proxy + url, { headers: this.getHeaders(86400) });
    const json = await result.json();
    const currencyInfo: CgCurrencyInfo = json;
    const platforms = currencyInfo.platforms as unknown as Record<string, string>;
    currencyInfo.platforms = [];
    if (!result.ok) {
      console.error("CG error", json);
      return undefined;
    }
    for (const [platformId, contractAddress] of Object.entries(platforms)) {
      if (platformId === "" && contractAddress === "") {
        continue;
      }
      currencyInfo.platforms.push({
        contractAddress: contractAddress,
        assetPlatform: this.assetPlatformsById[platformId],
      });
    }
    return currencyInfo;
  }

  static async getPlatforms() {
    const url = this.proxy + "https://api.coingecko.com/api/v3/asset_platforms";

    return await fetch(url, { headers: this.getHeaders(86400) }).then(async (res) => {
      const platforms: CgAssetPlatform[] = await res.json();
      if (!res.ok) {
        console.error("CG error", platforms);
        this.assetPlatformsById = {};
        return this.assetPlatformsById;
      }

      this.assetPlatformsById = {};
      for (const platform of platforms) {
        this.assetPlatformsById[platform.id] = platform;
      }
      return this.assetPlatformsById;
    });
  }

  private static getExchangeSlug(exchange: string): string {
    return this.map[exchange] ?? exchange;
  }

  private static getHeaders(expirationSeconds?: number) {
    if (expirationSeconds !== undefined) {
      return Object.assign({ "x-worker-cache-ttl": expirationSeconds }, this.headers);
    }
    return this.headers;
  }
}
